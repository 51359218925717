import { ReactNode } from 'react';

interface MainContentProps {
  children: ReactNode;
}
const MainContent = ({ children }: MainContentProps) => {
  return (
    <div className="flex min-h-screen w-full flex-col items-center">
      {children}
    </div>
  );
};

export default MainContent;
