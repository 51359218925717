import { MenuSubheading } from './ShopMenu';
import Accordion from '@ui/Accordion';
import NavLink from '@ui/Link/NavLink';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { staticCategories } from '@c/navigation/utils/categories';

export default function ShopBySportMenu({
  onClick,
  activeCategory,
  onClickCategory,
}: {
  onClick: () => void;
  activeCategory: string;
  onClickCategory: (category: string) => void;
}) {
  const category = staticCategories.find((c) => c.label === activeCategory);

  const router = useRouter();
  if (activeCategory === '') {
    return (
      <div>
        <MenuSubheading text="SHOP BY SPORT" />
        <div
          className={
            'flex flex-col gap-y-6 px-[1.6rem] text-[1.6rem] font-medium'
          }
        >
          {staticCategories.map((category) => (
            <div
              className="flex w-full items-center justify-between text-black"
              key={category.label}
              onClick={() => {
                onClickCategory(category.label);
              }}
            >
              <Link
                href={category.href}
                className="z-10 text-[1.8rem] font-medium"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
              >
                {category.label}
              </Link>

              <ChevronRightIcon className="h-[3rem] w-[3rem]" />
            </div>
          ))}

          <Link
            className="flex w-full items-center justify-between py-8 text-brand-secondary"
            href={'/shop/'}
          >
            <h3 className="text-[1.8rem] font-medium">View All</h3>

            <ArrowRightIcon className="h-[3rem] w-[3rem]" />
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          className="flex w-full items-center gap-x-4 p-[1.6rem]"
          onClick={() => {
            onClickCategory('');
          }}
        >
          <ArrowLeftIcon className="h-[2.4rem] w-[2.4rem] text-brand-secondary" />

          <Link
            href={category?.href ?? '#'}
            onClick={(e) => {
              e.stopPropagation();
              onClickCategory('');
            }}
            className="text-[1.8rem] font-medium uppercase text-brand-secondary"
          >
            {category?.label}
          </Link>
        </div>

        <div className="w-full px-[1rem] py-[2rem]">
          {category?.sections?.length && (
            <div className="flex flex-col px-[1.6rem] text-[1.8rem]">
              {category.sections.map((section) => (
                <Accordion
                  key={section.label}
                  arrowRight
                  title={section.label}
                  highlightHeaderOnOpen
                  noBoldTitle
                  mobileArrowRotation
                >
                  {section?.links?.length && (
                    <div className="flex flex-col px-[1.6rem]">
                      {section.links.map((link) => (
                        <NavLink
                          href={link.href}
                          onClick={() => {
                            onClick();
                          }}
                          key={link.label}
                          variant="secondary"
                        >
                          {link.label}
                        </NavLink>
                      ))}
                    </div>
                  )}
                  <div className="flex flex-col px-[1.6rem] text-brand-secondary">
                    <NavLink
                      href={section.href}
                      key={section.label}
                      onClick={() => onClick()}
                    >
                      See All
                    </NavLink>
                  </div>
                </Accordion>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
